.wrap_s2yaR {
    text-align: center;
}
.title_dTFB5 {
    font-size: 2.125em;
    font-weight: 700;
    letter-spacing: -2px;
    color: #2B2B2B;
}
@media (max-width: 425px) {
.title_dTFB5 {
        font-size: 1.5em;
        letter-spacing: -1px
}
    }
.list_2aftJ {
    margin: 48px 20px 0;
}
@media (max-width: 768px) {
.list_2aftJ {
        margin: 58px 20px 0
}
    }
@media (max-width: 425px) {
.list_2aftJ {
        margin: 42px 20px 0
}
    }
.item_3Kq4T {
    display: block;
    font-size: 1em;
    letter-spacing: -.5px;
    color: #7B7B7B;
}
.item_3Kq4T + .item_3Kq4T {
    margin: 42px 0 0;
}
.itemTitle_2ntfD {
    display: inline-block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 1em;
    letter-spacing: -.5px;
    color: #7B7B7B;
    cursor: pointer;
    line-height: 1.34;
}
.itemTitle_2ntfD:hover {
        text-decoration: underline;
    }
.itemDesc_2RAw6 {
    display: none;
    margin: 24px 0 0;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    background: #F5F5F5;
    padding: 32px 24px;
    font-size: 1em;
    line-height: 1.34;
    letter-spacing: -.5px;
    color: #7B7B7B;
    text-align: left;
}
@media (max-width: 768px) {
.itemDesc_2RAw6 {
        padding: 24px 20px
}
    }
.check_2TaLd {
    display: none;
}
.check_2TaLd:checked + .itemDesc_2RAw6 {
    display: block;
}
