.wrap_3L0TG {
    position: relative;
}
@media (max-width: 425px) {
.wrap_3L0TG {
        text-align: center
}
    }
.wrap_3L0TG:before {
        width: 636px;
        height: 732px;
        background: url("image/store_capture_rebranding.png") no-repeat;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        top: -170px;
        right: auto;
        bottom: auto;
        left: 44%;
        position: absolute;

        content: '';
    }
@media (max-width: 768px) {
.wrap_3L0TG:before {
            width: 514px;
            height: 593px;
            top: 100px;
            right: auto;
            bottom: auto;
            left: 26%;
            position: absolute
    }
        }
@media (max-width: 425px) {
.wrap_3L0TG:before {
            width: 288px;
            height: 332px;
            top: -webkit-calc(100% + 30px);
            top: -moz-calc(100% + 30px);
            top: calc(100% + 30px);
            right: -webkit-calc(50% - 144px);
            right: -moz-calc(50% - 144px);
            right: calc(50% - 144px);
            bottom: auto;
            left: auto;
            position: absolute
    }
        }
.title_2CX1c {
    font-size: 2.625em;
    font-weight: 500;
    text-align: left;
    color: #2B2B2B;
}
@media (max-width: 425px) {
.title_2CX1c {
        font-size: 1.5em;
        font-weight: 700;
        text-align: center
}
    }
.title_2CX1c > br {
        display: none;
    }
@media (max-width: 768px) {
.title_2CX1c > br {
            display: block;
            line-height: 1.24;
            color: #434444
    }
        }
@media (max-width: 425px) {
.title_2CX1c > br {
            display: none
    }
        }
.list_3N8L7 {
    position: relative;
    margin: 32px 0 0;
    text-align: left;
}
@media (max-width: 768px) {
.list_3N8L7 {
        margin: 24px 0 0
}
    }
@media (max-width: 425px) {
.list_3N8L7 {
        margin: 20px 0 0;
        text-align: center
}
    }
.item_39vRK {
    font-size: 1.25em;
    line-height: 1.6;
    letter-spacing: -0.5px;
    color: #434444;
}
@media (max-width: 768px) {
.item_39vRK {
        font-size: 1em;
        line-height: 1.5
}
    }
.link_YOnXx {
    display: inline-block;
    position: relative;
    margin: 32px 0 0;
    padding: 34px 60px;
    font-size: 1.375em;
    letter-spacing: -0.5px;
    text-align: center;
    color: #fff;
    -webkit-border-radius: 45px;
       -moz-border-radius: 45px;
            border-radius: 45px;
    background: #2CB783;
}
@media (max-width: 768px) {
.link_YOnXx {
        margin: 24px 0 0;
        padding: 22px 44px;
        font-size: 1.25em
}
    }
@media (max-width: 425px) {
.link_YOnXx {
        margin: 20px 0 0;
        padding: 24px 48px;
        font-size: 1em
}
    }
.link_YOnXx:hover {
        background: #149566;
    }
