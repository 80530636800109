.wrap_yQlzC {
    background: #2CB783;
}
.list_2tPPr {
    font-size: 1em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    letter-spacing: -.5px;
}
.list_2tPPr:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
}
.bi_29WAn {
    float: left;
    font-size: 0;
}
@media (max-width: 768px) {
.bi_29WAn {
        float: inherit;
        text-align: center
}
    }
.bi_29WAn > h1 {
        display: inline-block;
        width: 146px;
        height: 26px;
        margin: -4px 0 0;
        background: url("image/logo_header_rebranding.svg") no-repeat;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
        color: transparent;
        vertical-align: middle;
    }
@media (max-width: 425px) {
.bi_29WAn > h1 {
            width: 132px;
            height: 23px;
            margin: 0
    }
        }
.link_3dIw5 {
    float: right;
}
@media (max-width: 768px) {
.link_3dIw5 {
        display: none
}
    }
