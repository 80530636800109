.wrap_3SkXA {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    display: block;
    width: 100%;
    height: 100%;
}
.dim_yD9P6 {
    position: absolute;
    left: 0;
    top: 0;

    display: block;
    width:100%;
    height: 100%;

    background: #000000;

    background: rgba(0, 0, 0, .56);
}
.dialog_2IEMV {
    position: absolute;
    left: -webkit-calc(50% - 320px);
    left: -moz-calc(50% - 320px);
    left: calc(50% - 320px);
    top: -webkit-calc(50% - 239px);
    top: -moz-calc(50% - 239px);
    top: calc(50% - 239px);

    display: block;
    width: 640px;
    height: 478px;

    background: #fff;
    -webkit-box-shadow: 0 17px 50px rgba(0, 0, 0, .19);
       -moz-box-shadow: 0 17px 50px rgba(0, 0, 0, .19);
            box-shadow: 0 17px 50px rgba(0, 0, 0, .19);
}
@media (max-width: 425px) {
.dialog_2IEMV {
        width: -webkit-calc(100% - 24px);
        width: -moz-calc(100% - 24px);
        width: calc(100% - 24px);
        left: 12px
}
    }
.title_2PWag {
    display: block;
    width: 100%;
    height: 56px;

    color: #7B7B7B;
    font-size: 1.125em;
    text-align: center;
    line-height: 56px;
    letter-spacing: -.5px;

    border-bottom: 2px solid #F5F5F5;
}
@media (max-width: 425px) {
.title_2PWag {
        height: 42px;
        font-size: 1em;
        line-height: 42px
}
    }
.cancel_1qaCm {
    cursor: pointer;

    position: absolute;
    right: 0;
    top: 0;

    display: block;
    padding: 0 20px;

    color: #ACACAC;
    font-size: 1em;
    text-decoration: underline;
    line-height: 56px;
    letter-spacing: -.5px;
}
@media (max-width: 425px) {
.cancel_1qaCm {
        padding: 0 12px;
        font-size: 0.875em;
        line-height: 42px
}
    }
.content_2C4cD {
    display: block;
    width: 100%;
    height: -webkit-calc(100% - 58px);
    height: -moz-calc(100% - 58px);
    height: calc(100% - 58px);
}
