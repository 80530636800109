.wrap_1_Sjh {
    overflow: hidden;
}
.info_5kOb0 {
    height: 866px;
    padding: 42px 30px 0;
    background: #2CB783;
}
@media (max-width: 768px) {
.info_5kOb0 {
        height: 1162px;
        padding: 32px 0 0
}
    }
@media (max-width: 425px) {
.info_5kOb0 {
        height: 949px
}
    }
@media (max-width: 320px) {
.info_5kOb0 {
        height: 934px
}
    }
.certificate_3SGrM {
    padding: 0 0 90px;
}
@media (max-width: 768px) {
.certificate_3SGrM {
        padding: 0 0 72px
}
    }
@media (max-width: 425px) {
.certificate_3SGrM {
        padding: 0 0 56px
}
    }
.status_3D9GV {
    padding: 80px 0 186px;
    border-bottom: 1px solid #e1e1e1;
}
@media (max-width: 768px) {
.status_3D9GV {
        padding: 98px 0 120px
}
    }
@media (max-width: 425px) {
.status_3D9GV {
        padding: 40px 0 50px
}
    }
.detail_38NoB {
    padding: 210px 40px 250px;
}
@media (max-width: 768px) {
.detail_38NoB {
        padding: 60px 32px 440px
}
    }
@media (max-width: 425px) {
.detail_38NoB {
        padding: 60px 24px 370px
}
    }
.myData_1_3Jh {
    padding: 120px 40px;
    background-color: #F5F5F5;
}
@media (max-width: 768px) {
.myData_1_3Jh {
        padding: 70px 44px
}
    }
@media (max-width: 425px) {
.myData_1_3Jh {
        padding: 40px 24px
}
    }
.help_s9Lml {
    padding: 90px 0;
}
@media (max-width: 768px) {
.help_s9Lml {
        padding: 72px 0
}
    }
@media (max-width: 425px) {
.help_s9Lml {
        padding: 56px 0
}
    }
.footer_e-XeK {
    padding: 96px 20px 90px;
    background: #F5F5F5;
}
@media (max-width: 768px) {
.footer_e-XeK {
        padding: 72px 20px
}
    }
@media (max-width: 425px) {
.footer_e-XeK {
        padding: 56px 20px 160px 20px
}
    }
.floatButton_26lRc {
    display: none;
}
@media (max-width: 425px) {
.floatButton_26lRc {
        width: 100%;
        height: 57px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        background: #2CB783
}

        .floatButton_26lRc > div {
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.89;
            letter-spacing: -0.5px;
            color: #fff;
        }
    }
.floatButtonWrap_ttMxm {
    visibility: visible;
    position: fixed;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    bottom: 0;
    z-index: 10;
}
.floatButtonWrapHide_1CQ_2 {
    visibility: hidden;
}
.eventButton_3298E {
    display: none;
}
@media (max-width: 425px) {
.eventButton_3298E {
        display: block
}
    }
