.wrap_1ZAxC {
    display: block;
    width: 100%;
    height: 100%;
}
.wrap_1ZAxC iframe {
        width: 100%;
        height: 100%;
    }
.informationWrap_odJm7 {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
}
.information_2kDhg {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    color: #5B5C5C;
}
@media (max-width: 425px) {
.information_2kDhg {
        font-size: 12px
}
    }
.title_3cFtA {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    color: #434444;
}
@media (max-width: 425px) {
.title_3cFtA {
        font-size: 16px
}
    }
.description_2ktNx {
    margin: 20px 0 0;
    line-height: 1.34;
}
.link_2TCia {
    display: inline-block;
    margin: 100px 0 0;
    line-height: 1;
    text-decoration: underline;
}
@media (max-width: 425px) {
.link_2TCia {
        margin: 40px 0 0
}
    }
