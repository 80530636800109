.wrap_1OmN9 {
    max-width: 768px;
    margin: 0 auto;
    text-align: center;
}
.title_OR4wK {
    display: block;
    width: 257px;
    height: 45px;
    margin: 0 auto;
    background: url("image/logo_footer_rebranding.svg") no-repeat;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    font-size: 0;
    color: transparent;
}
@media (max-width: 768px) {
.title_OR4wK {
        width: 232px;
        height: 41px
}
    }
@media (max-width: 425px) {
.title_OR4wK {
        width: 185px;
        height: 33px
}
    }
.stores_2Arco {
    display: inline-block;
    margin: 56px 0 0;
}
@media (max-width: 768px) {
.stores_2Arco {
        margin: 48px 0 0
}
    }
@media (max-width: 425px) {
.stores_2Arco {
        margin: 42px 0 0
}
    }
.stores_2Arco:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}
.store_KYOXu {
    float: left;
}
.store_KYOXu + .store_KYOXu {
    margin: 0 0 0 16px;
}
@media (max-width: 425px){
.store_KYOXu + .store_KYOXu {
        margin: 0 0 0 12px
}
    }
.icon_1e4NY {
    display: block;
    width: 184px;
    height: 62px;
    font-size: 0;
    color: transparent;
}
@media (max-width: 425px) {
.icon_1e4NY {
        width: 125px;
        height: 42px
}
    }
.google_21wwz {
    background: url("image/google.svg") no-repeat;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
}
.google_21wwz:before {
        display: none;
        content: '';
    }
.desktopGoogle_3IJrU {
}
.desktopGoogle_3IJrU:hover {
        background: url("image/google.svg") no-repeat;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
    }
.apple_2XU3S {
    background: url("image/apple.svg") no-repeat;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
}
.apple_2XU3S:before {
        display: none;
        content: '';
    }
.desktopApple_2I22F {
}
.desktopApple_2I22F:hover {
        background: url("image/apple.svg") no-repeat;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
    }
.linkContainer_2kyZ1 {
    margin: 64px 0 0;
}
@media (max-width: 768px) {
.linkContainer_2kyZ1 {
        margin: 48px 0 0
}
    }
@media (max-width: 425px) {
.linkContainer_2kyZ1 {
        margin: 56px 0 0
}
    }
.linkContainer_2kyZ1:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}
.links_YL2Jg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 0 32px;
    width: 100%;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: space-around;
        -ms-flex-line-pack: distribute;
            align-content: space-around;
    height: 120px;
}
@media (max-width: 425px) {
.links_YL2Jg {
        -webkit-justify-content: space-around;
            -ms-flex-pack: distribute;
                justify-content: space-around;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column
}
    }
.linkText_3bTSA {
    display: inline-block;
    font-size: 1em;
    color: #7B7B7B;
    letter-spacing: -.5px;
    white-space: nowrap;
}
@media (max-width: 768px) {
.linkText_3bTSA {
        font-size: 15.2px;
        font-size: 0.95rem
}
    }
.mydataCertificatePrivacyPolicyLinkText_1ibta {
    font-weight: bold;
}
.company_I3RZz {
    margin: 64px 0 0;
    font-size: 1em;
    line-height: 1.57;
    color: #7B7B7B;
    letter-spacing: -.5px;
}
@media (max-width: 768px) {
.company_I3RZz {
        margin: 42px 0 0;
        font-size: .9em
}
    }
.company_I3RZz > strong {
        font-weight: 700;
    }
.company_I3RZz > a {
        color: #7B7B7B;
    }
.copyright_3eaz4 {
    margin: 64px 0 0;
    font-size: 1em;
    color: #7B7B7B;
    letter-spacing: -.5px;
}
@media (max-width: 768px) {
.copyright_3eaz4 {
        margin: 42px 0 0;
        font-size: .9em
}
    }
.newLine_2YfPY {
    display: none;
}
@media (max-width: 425px) {
.newLine_2YfPY {
        display: block
}
    }
