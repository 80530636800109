.pcImage_3HV26 {
    display: block;
    width: 100%;
}
@media (max-width: 768px) {
.pcImage_3HV26 {
        display: none
}
    }
.tabletImage_1ZO3s {
    display: none;
    width: 100%;
}
@media (max-width: 768px) {
.tabletImage_1ZO3s {
        display: block
}
    }
@media (max-width: 425px) {
.tabletImage_1ZO3s {
        display: none
}
    }
.mobileImage_rjgza {
    display: none;
    width: 100%;
}
@media (max-width: 425px) {
.mobileImage_rjgza {
        display: block
}
    }
