.wrap_2ycEh {
    position: fixed;
    display: block;
    bottom: 0;
    width: 100%;
    z-index: 10;
    padding-bottom: env(safe-area-inset-bottom);
    height: 60px + env(safe-area-inset-bottom);
}

.issueButton_3LJV2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-calc(100% - 50px);
    width: -moz-calc(100% - 50px);
    width: calc(100% - 50px);
    height: 60px;
    margin: 0 auto 20px;
    -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
            border-radius: 30px;
    color: #fff;
    background: #FD8700;
    
    -webkit-box-align: center;
    
    -webkit-align-items: center;
    
       -moz-box-align: center;
    
        -ms-flex-align: center;
    
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 18px;
    font-weight: 700;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12);
       -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12);
    opacity: 1.0;
    -webkit-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

.hidingIssueButton_3q4FD {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}
