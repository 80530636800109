.wrap_2ciZ2:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
}
.title_2gzlX {
    float: left;
    width: 50%;
    padding: 30px 0 0;
    font-size: 2.625em;
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: 1.4;
    color: #2B2B2B;
}
@media (max-width: 768px) {
.title_2gzlX {
        float: inherit;
        font-size: 2.625em;
        width: 100%;
        padding: 0
}
    }
@media (max-width: 425px) {
.title_2gzlX {
        font-size: 1.5em;
        font-weight: 700;
        text-align: center;
        letter-spacing: -1px
}
    }
.newsItem_1nk2q {
    border-bottom: 2px solid #E1E1E1;
}
.newsItem_1nk2q > a {
        display: block;
        padding: 20px 0;
    }
.newsItem_1nk2q:last-child {
    border-bottom: 0;
}
.newsList_TGRWP {
    float: left;
    width: 50%;
}
@media (max-width: 768px) {
.newsList_TGRWP {
        float: inherit;
        width: 100%;
        margin: 20px 0 0
}
    }
.newsTitle_3ZbXP {
    display: block;
    font-size: 1.5em;
    letter-spacing: -1px;
    color: #434444;
    line-height: 1.34;
}
@media (max-width: 768px) {
.newsTitle_3ZbXP {
        font-size: 1.5em
}
    }
@media (max-width: 425px) {
.newsTitle_3ZbXP {
        font-size: 1em;
        letter-spacing: -.5px
}
    }
.infoList_3yaIM {
    display: block;
    margin: 6px 0 0;
}
@media (max-width: 768px) {
.infoList_3yaIM {
        margin: 10px 0 0
}
    }
.infoList_3yaIM:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}
.infoItem_t7PCW {
    float: left;
}
.companyTitle_366SV {
    font-size: 1.25em;
    font-weight: 700;
    letter-spacing: -.5px;
    color: #2CB783;
}
@media (max-width: 768px) {
.companyTitle_366SV {
        font-size: 1.25em
}
    }
@media (max-width: 425px) {
.companyTitle_366SV {
        font-size: 0.875em
}
    }
.date_3Bztf {
    margin: 0 0 0 20px;
    font-size: 1.25em;
    font-weight: 500;
    letter-spacing: -.5px;
    color: #C0C0C0;
}
@media (max-width: 768px) {
.date_3Bztf {
        font-size: 1.25em
}
    }
@media (max-width: 425px) {
.date_3Bztf {
        font-size: 0.875em
}
    }
