.wrap_zqAlR {
    margin: -104px 0 0;
    padding: 80px 0;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    background: #fff;
    -webkit-box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .12);
       -moz-box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .12);
            box-shadow: 0 10px 34px 0 rgba(0, 0, 0, .12);
    text-align: center;
}
@media (max-width: 768px) {
.wrap_zqAlR {
        margin: 0;
        padding: 72px 0
}
    }
@media (max-width: 425px) {
.wrap_zqAlR {
        padding: 56px 0
}
    }
.title_1Kbdm {
    font-size: 2.125em;
    font-weight: bold;
    color: #2B2B2B;
}
@media (max-width: 768px) {
.title_1Kbdm {
        font-size: 2.125em;
        letter-spacing: -1.4px
}
    }
@media (max-width: 425px) {
.title_1Kbdm {
        font-size: 1.5em;
        line-height: 1.42;
        letter-spacing: -1px
}
    }
.title_1Kbdm > br {
        display: none;
    }
@media (max-width: 425px) {
.title_1Kbdm > br {
            display: block
    }
        }
.submit_21W9G {
    display: inline-block;
    margin: 20px 0 0;
    padding: 22px 74px;
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    background: #2CB783;
    font-size: 1.25em;
    color: #fff;
    letter-spacing: -.5px;
}
@media (max-width: 768px) {
.submit_21W9G {
        margin: 24px 0 0
}
    }
@media (max-width: 425px) {
.submit_21W9G {
        margin: 20px 0 0;
        font-size: 1em
}
    }
.submit_21W9G:hover {
        background: #149566;
    }
.list_2th-Z {
    margin: 64px 0 0;
}
@media (max-width: 768px) {
.list_2th-Z {
        margin: 48px 0 0
}
    }
.list_2th-Z:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}
.item_1ml4P {
    position: relative;
    float: left;
    width: 33.33%;
}
@media (max-width: 425px) {
.item_1ml4P {
        float: inherit;
        width: 100%
}
    }
.item_1ml4P:after {
        display: block;
        width: 100%;
        min-height: 200px;
        margin: 10px 0 0;

        content: '';
    }
@media (max-width: 768px) {
.item_1ml4P:after {
            margin: 8px 0 0;
            min-height: 158px
    }
        }
@media (max-width: 425px) {
.item_1ml4P:after {
            margin: 10px 0 0;
            min-height: 164px
    }
        }
.item_1ml4P:nth-child(1) .order_3x6by {
        background: url("image/step1.png") no-repeat center;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
    }
.item_1ml4P:nth-child(1):after {
        background: url("image/step1_desc.png") no-repeat top;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
        content: '';
    }
.item_1ml4P:nth-child(2) .order_3x6by {
        background: url("image/step2.png") no-repeat center;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
    }
.item_1ml4P:nth-child(2):after {
        background: url("image/step2_desc.png") no-repeat top;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
        content: '';
    }
.item_1ml4P:nth-child(3) .order_3x6by {
        background: url("image/step3.png") no-repeat center;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
    }
.item_1ml4P:nth-child(3):after {
        background: url("image/step3_desc.png") no-repeat top;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
        content: '';
    }
@media (max-width: 425px) {
.item_1ml4P + .item_1ml4P {
        margin: 48px 0 0
}
    }
.order_3x6by {
    display: inline-block;
    width: 86px;
    height: 32px;
    font-size: 0;
    color: transparent;
    vertical-align: top;
}
.desc_1KIPZ {
    display: block;
    margin: 8px 0 0;
    padding: 0 4px;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: -0.5px;
    color: #2B2B2B;
}
@media (max-width: 768px) {
.desc_1KIPZ {
        padding: 0 12px
}
    }
@media (max-width: 768px) {
.desc_1KIPZ > br {
            display: none
    }
        }
@media (max-width: 425px) {
.desc_1KIPZ > br {
            display: block
    }
        }
