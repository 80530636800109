.wrap_14qmU {
    width: 100%;
    margin: 13px 0 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
@media (max-width: 768px) {
.wrap_14qmU {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
           -moz-box-orient: vertical;
           -moz-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }
.videoWrap_X-ToR {
    position: relative;
    width: 260px;
    height: 534px;
    margin: 80px 0 0 0;
}
@media (max-width: 768px) {
.videoWrap_X-ToR {
        margin: -28px 0 0 0;
        width: 204px;
        height: 418px;
        top: -webkit-calc(100% + 56px);
        top: -moz-calc(100% + 56px);
        top: calc(100% + 56px);
        right: -webkit-calc(50% - 110px);
        right: -moz-calc(50% - 110px);
        right: calc(50% - 110px);
        bottom: auto;
        left: auto;
        position: absolute
}
    }
@media (max-width: 425px) {
.videoWrap_X-ToR {
        width: 184px;
        height: 380px;
        top: -webkit-calc(100% + 56px);
        top: -moz-calc(100% + 56px);
        top: calc(100% + 56px);
        right: -webkit-calc(50% - 98px);
        right: -moz-calc(50% - 98px);
        right: calc(50% - 98px);
        bottom: auto;
        left: auto;
        position: absolute
}
    }
.frame_3nTZB {
    display: block;
    width: -webkit-calc(100% + 56px);
    width: -moz-calc(100% + 56px);
    width: calc(100% + 56px);
    height: -webkit-calc(100% + 77px);
    height: -moz-calc(100% + 77px);
    height: calc(100% + 77px);
    background: url("image/video_frame.png") no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    top: -36px;
    right: auto;
    bottom: auto;
    left: -40px;
    position: absolute;
}
@media (max-width: 768px) {
.frame_3nTZB {
        width: -webkit-calc(100% + 48px);
        width: -moz-calc(100% + 48px);
        width: calc(100% + 48px);
        height: -webkit-calc(100% + 61px);
        height: -moz-calc(100% + 61px);
        height: calc(100% + 61px);
        top: -28px;
        right: auto;
        bottom: auto;
        left: -32px;
        position: absolute
}
    }
@media (max-width: 425px) {
.frame_3nTZB {
        width: -webkit-calc(100% + 46px);
        width: -moz-calc(100% + 46px);
        width: calc(100% + 46px);
        height: -webkit-calc(100% + 54px);
        height: -moz-calc(100% + 54px);
        height: calc(100% + 54px);
        top: -26px;
        right: auto;
        bottom: auto;
        left: -28px;
        position: absolute
}
    }
.video_2jj01 {
    background: transparent;
    max-width: -webkit-calc(100% + 2px);
    max-width: -moz-calc(100% + 2px);
    max-width: calc(100% + 2px);
    max-height: -webkit-calc(100% + 2px);
    max-height: -moz-calc(100% + 2px);
    max-height: calc(100% + 2px);
}
@media (max-width: 425px) {
.video_2jj01 {
        max-width: -webkit-calc(100% + 4px);
        max-width: -moz-calc(100% + 4px);
        max-width: calc(100% + 4px);
        max-height: 100%
}
    }
.infoWrap_1xo3p {
    margin-left: -webkit-calc(15% - 102px);
    margin-left: -moz-calc(15% - 102px);
    margin-left: calc(15% - 102px);
}
@media (max-width: 768px) {
.infoWrap_1xo3p {
        width: 100%;
        height: 43%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
           -moz-box-orient: vertical;
           -moz-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 0
}
    }
.infoContents_HVBt7 {
    margin: 0 0 0 13px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
@media (max-width: 768px) {
.infoContents_HVBt7 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 0;
        padding: 0 10px
}
    }
.infoTitle_1OjQg {
    margin: 21px 0 0 0;
    font-size: 80px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: -2px;
    color: #fff;
    text-align: start;
}
@media (max-width: 768px) {
.infoTitle_1OjQg {
        text-align: center
}
    }
@media (max-width: 425px) {
.infoTitle_1OjQg {
        font-size: 48px
}
    }
.title_MXXMs {
    font-size: 70px;
    font-weight: 600;
    max-width: 484px;
    width: 50vw;
    margin: 160px 0 40px -4px;
    height: 17vw;
    min-height: 170px;
    max-height: 225px;
    text-align: left;
    color: #fff;
}
.title_MXXMs h1 {
        white-space: no-wrap;
        margin-bottom: 15px;
        margin-top: -6px;
    }
.title_MXXMs p {
        font-size: 32px;
        font-weight: 400;
        color: white;
        margin-top: 38px;
    }
@media (max-width: 1200px) {
.title_MXXMs {
        font-size: 60px
}

        .title_MXXMs p {
            font-size: 30px;
        }
    }
@media (max-width: 1024px) {
.title_MXXMs {
        font-size: 6vw
}

        .title_MXXMs p {
            font-size: 3vw;
        }
    }
@media (max-width: 768px) {
.title_MXXMs {
        width: 100%;
        text-align: center;
        font-size: 52px
}

        .title_MXXMs h1 {
            white-space: normal;
        }

        .title_MXXMs p {
            font-size: 26px;
        }
    }
@media (max-width: 425px) {
.title_MXXMs {
        font-size: 12.3vw;
        margin: 40px 0 25px 0
}

        .title_MXXMs p {
            font-size: 6.5vw;
            margin-top: 30px;
        }
    }
.buttons_1Q2qo {
    width: 260px;
    height: 90px;
    margin: 20px 0 0 0;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
    font-size: 26px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.85;
    letter-spacing: -0.6px;
    color: #2B2B2B;
    background: #FFE200;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 768px) {
.buttons_1Q2qo {
        width: 210px;
        height: 66px;
        font-size: 20px;
        margin-bottom: 41px
}
    }
@media (max-width: 425px) {
.buttons_1Q2qo {
        width: 172px;
        height: 54px;
        font-size: 18px;
        margin-bottom: 41px
}
    }
