.container_1blNX {
    position: relative;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
}
.detailImageContainer_5MvEk {
    max-width: 2056px;
}
.fixed_1wnVy {
    height: 100%;
    overflow: hidden;
    position: relative;
}
