.wrap_3xNRa {
    position: relative;
}
.wrap_3xNRa:before {
        display: inline-block;
        width: 418px;
        height: 417px;
        background: url("image/status_left_bg.png") no-repeat;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        top: -170px;
        right: auto;
        bottom: auto;
        left: 0;
        position: absolute;

        content: '';
    }
@media (max-width: 768px) {
.wrap_3xNRa:before {
            width: 402px;
            height: 348px;
            top: -180px;
            right: auto;
            bottom: auto;
            left: 0;
            position: absolute
    }
        }
@media (max-width: 425px) {
.wrap_3xNRa:before {
            display: block;
            width: 100%;
            height: 140px;
            background: url("image/status_mobile_bg.png") no-repeat;
            -webkit-background-size: cover;
               -moz-background-size: cover;
                    background-size: cover;
            top: -80px;
            right: auto;
            bottom: auto;
            left: 0;
            position: absolute
    }
        }
.wrap_3xNRa:after {
        display: inline-block;
        width: 659px;
        height: 417px;
        background: url("image/status_right_bg.png") no-repeat;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        top: -166px;
        right: 0;
        bottom: auto;
        left: auto;
        position: absolute;

        content: '';
    }
@media (max-width: 768px) {
.wrap_3xNRa:after {
            width: 547px;
            height: 348px;
            top: -170px;
            right: 0;
            bottom: auto;
            left: auto;
            position: absolute
    }
        }
@media (max-width: 425px) {
.wrap_3xNRa:after {
            display: none
    }
        }
.list_3s7-a {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;
    text-align: center;
    z-index: 10;
    vertical-align: center;
}
.item_2JPHY {
    display: table-cell;
}
@media (max-width: 425px) {
.item_2JPHY {
        display: table-row
}
    }
@media (max-width: 425px) {
.item_2JPHY + .item_2JPHY .term_1ZNuA {
            margin: 64px 0 0
    }
        }
.term_1ZNuA {
    display: block;
    font-size: 2.25em;
    color: #2B2B2B;
}
@media (max-width: 768px) {
.term_1ZNuA {
        font-size: 1.5em
}
    }
@media (max-width: 425px) {
.term_1ZNuA {
        font-size: 2em
}
    }
.figure_EjJz1 {
    display: block;
    margin: 22px 0 0;
    font-size: 4.25em;
    font-weight: 700;
    color: #2CB783;
}
@media (max-width: 768px) {
.figure_EjJz1 {
        margin: 20px 0 0;
        font-size: 2.5em
}
    }
@media (max-width: 425px) {
.figure_EjJz1 {
        margin: 16px 0 0;
        font-size: 4em
}
    }
